import React, { useState } from 'react'
import { useTranslations } from '../../hooks/use-translations'
import { WarmConstruction } from '../../components/promo/warm-construction'
import SEO from '../../components/seo'
import Button from '../../components/button/button'
import ContactForm from '../../components/contact-form'

const WarmConstructionPage: React.FC = () => {
  const { locale, t } = useTranslations()
  const [modal, setModal] = useState(false)
  return (
    <>
      <SEO
        title="Signal Inspection Promo 30"
        description={
          locale === 'en'
            ? 'Warm construction with SIGNAL'
            : 'Теплая стройка с SIGNAL'
        }
      />
      <section className="is-relative has-background-dark">
        <WarmConstruction type="page" />
        <div className="container">
          <div className="columns">
            <div className="column is-10-touch is-offset-1-touch is-6-desktop">
              <Button
                size="medium"
                className="is-fullwidth py-4 my-3"
                onClick={() => setModal(true)}
              >
                <span className="my-1">{t('Want to buy')}</span>
              </Button>
            </div>
          </div>
        </div>
      </section>
      {modal && (
        <ContactForm
          onClose={() => setModal(false)}
          title={t(`${t('Buy')} Signal`)}
          requestType="Buy"
        />
      )}
    </>
  )
}

export default WarmConstructionPage
