// extracted by mini-css-extract-plugin
export var wrap = "warm-construction-module--wrap--zCxys";
export var bg = "warm-construction-module--bg--vmzud";
export var bg__img = "warm-construction-module--bg__img--9kVUD";
export var box = "warm-construction-module--box--6zggv";
export var heading = "warm-construction-module--heading--3dT+f";
export var content = "warm-construction-module--content--i-1TK";
export var big = "warm-construction-module--big--aBQf3";
export var leadLine = "warm-construction-module--leadLine--kAKUe";
export var lead = "warm-construction-module--lead--AYwi5";
export var device = "warm-construction-module--device--7SnRi";
export var text = "warm-construction-module--text--2iLWG";
export var product = "warm-construction-module--product--dL+QU";
export var product__tag = "warm-construction-module--product__tag--lGtF2";
export var product__oldPrice = "warm-construction-module--product__oldPrice--u88Zf";