import { graphql, Link, useStaticQuery } from 'gatsby'
import { GatsbyImage } from 'gatsby-plugin-image'
import React, { useState } from 'react'
import cn from 'classnames'

import { useTranslations } from '../../hooks/use-translations'
import * as s from './warm-construction.module.sass'
import ContactForm from '../contact-form'

export const WARM_CONSTRUCTION_PROMO_ID = 'warm_construction_30'

type ComponentType = 'banner' | 'page'
type ModalType = 'buy' | 'demo'

const title: Record<string, string> = {
  ru: 'Громкий Signal потеплений на\u00A0стройке',
  en: 'Loud Signal Warming at\u00A0Construction\u00A0Site',
}

const lead: Record<ComponentType, Record<string, JSX.Element>> = {
  banner: {
    ru: (
      <>
        Получи <span className="has-text-white">30% скидку</span>
        <br />
        на Signal Inspection
        <br />
        только до конца 2020 года
      </>
    ),
    en: (
      <>
        Get <span className="has-text-white">30% discount</span>
        <br />
        at Signal Inspection
        <br />
        only until the end of 2020
      </>
    ),
  },
  page: {
    ru: (
      <>
        Сократи затраты на&nbsp;строительный контроль!
        <br />
        Получи скидку 30% на Signal Inspection
        <br />
        для Autodesk BIM&nbsp;360
      </>
    ),
    en: (
      <>
        Reduce construction control costs!
        <br />
        Get 30% Off Signal Inspection
        <br />
        for Autodesk BIM&nbsp;360
      </>
    ),
  },
}

const getText = (
  type: ComponentType,
  lang: string,
  getPath: (url: string) => string,
  onOpenModal?: (modalType: ModalType) => void
): JSX.Element => {
  if (type === 'page') {
    switch (lang) {
      case 'en':
        return (
          <>
            <p>
              Nothing is as expensive in construction as lost time. Signal
              products can help you reduce your management decision time.
            </p>
            <p>
              <strong>
                Only until December 30, 2020, get a 30% discount on the Signal
                Inspection module!
              </strong>
            </p>
            <p>
              <Link to={getPath('/')} className="has-text-primary">
                Signal Inspection
              </Link>{' '}
              is an application for remote visual monitoring and analysis of the
              real situation on the construction site. The plugin works as an
              add-on for Autodesk BIM 360 and displays 360 photos linked to
              plans.
            </p>
            <p>Detailed terms of the promotion:</p>
            <ol>
              <li>
                Purchase any number of Signal Inspection licenses (either
                separately or as part of the Powerpack) and receive a{' '}
                <strong>30% discount.</strong>
              </li>
              <li>
                For a single purchase of more than 10 BIM 360 slots, the Signal
                Inspection discount (separately or in the Powerpack) will be
                <strong> 35%</strong>.
              </li>
              <li>
                To receive a discount when placing an order, it is enough{' '}
                <span
                  className="has-text-primary cursor-pointer"
                  onClick={() => onOpenModal?.('buy')}
                >
                  to leave a request
                </span>{' '}
                or inform your supplier of the code “Warm construction with
                Signal” when placing an order by phone.
              </li>
              <li>
                <strong>The promotion is valid until December 30, 2020.</strong>
              </li>
            </ol>
            <p>
              <Link to={getPath('/#modules')} className="has-text-primary">
                Learn more
              </Link>{' '}
              about other Signal apps.
            </p>
            <p>
              If you are not yet familiar with the Signal Inspection module, we
              suggest that you install{' '}
              <span
                className="has-text-primary cursor-pointer"
                onClick={() => onOpenModal?.('demo')}
              >
                the demo version
              </span>
              .
            </p>
          </>
        )
      default:
        return (
          <>
            <p>
              Ничего не обходится так дорого в строительстве, как упущенное
              время. Продукты Signal помогут вам сократить время для принятия
              управленческого решения.
            </p>
            <p>
              <strong>
                Только до 30 декабря 2020 года получи скидку 30% на модуль
                Signal Inspection!
              </strong>
            </p>
            <p>
              <Link to={getPath('/')} className="has-text-primary">
                Signal Inspection
              </Link>{' '}
              – приложение для удаленного визуального мониторинга и анализа
              реальной ситуации на стройплощадке. Плагин работает в качестве
              дополнительного модуля Autodesk BIM 360 и отображает фото 360 с
              привязкой к планам.
            </p>
            <p>Подробные условия промо-акции:</p>
            <ol>
              <li>
                При приобретении любого количества лицензий модуля Signal
                Inspection (отдельно или в комплекте Powerpack) вы получите{' '}
                <strong>скидку 30%</strong>.
              </li>
              <li>
                При единовременном приобретении более 10 мест BIM 360 скидка на
                Signal Inspection (отдельно или в комплекте Powerpack) составит
                <strong> 35%</strong>.
              </li>
              <li>
                Для получения скидки при оформлении заказа достаточно{' '}
                <span
                  className="has-text-primary cursor-pointer"
                  onClick={() => onOpenModal?.('buy')}
                >
                  оставить заявку
                </span>{' '}
                или сообщить вашему поставщику код «Теплая стройка с Signal» при
                оформлении заказа по телефону.
              </li>
              <li>
                <strong>Акция действует до 30 декабря 2020 года.</strong>
              </li>
            </ol>
            <p>
              <Link to={getPath('/#modules')} className="has-text-primary">
                Подробнее
              </Link>{' '}
              о других приложениях Signal.
            </p>
            <p>
              Если вы еще не знакомы с модулем Signal Inspection, то предлагаем
              вам установить{' '}
              <span
                className="has-text-primary cursor-pointer"
                onClick={() => onOpenModal?.('demo')}
              >
                демо-версию
              </span>
              .
            </p>
          </>
        )
    }
  }
  switch (lang) {
    case 'en':
      return (
        <>
          <p>
            Winter is coming, it's getting colder and colder to work
            at&nbsp;the&nbsp;facility, and in Signal we have a new warming offer
            -<strong> 30% discount on the Inspection module</strong> for remote
            visual monitoring and analysis of the real situation on the
            construction site.
          </p>
          <p>
            When purchasing the Signal Inspection module separately or included
            in Powerpack (any quantity) from December 1st to December 30th you
            get
            <strong> a 30% discount</strong>. To get a discount, just remember
            the code: <strong>"Warm construction with Signal"</strong> and
            inform your supplier.
          </p>
        </>
      )
    default:
      return (
        <>
          <p>
            Наступает зима, все холоднее становится работать на&nbsp;объекте, а
            у нас в&nbsp;Signal новое согревающее предложение –
            <strong> 30% скидки на модуль Inspection </strong>
            для&nbsp;удаленного визуального мониторинга и анализа реальной
            ситуации на&nbsp;стройплощадке.
          </p>
          <p>
            При приобретении модуля Signal Inspection отдельно или в комплекте
            Powerpack (любое количество) с&nbsp;1&nbsp;по&nbsp;30&nbsp;декабря
            вы получаете
            <strong> скидку 30%</strong>. Для&nbsp;получения скидки достаточно
            запомнить код: <strong>«Теплая стройка с Signal» </strong>
            и&nbsp;сообщить вашему поставщику.
          </p>
        </>
      )
  }
}

const items = [
  {
    title: 'Inspection',
    count: 10,
    price: '84\u00A0000',
    oldPrice: '120\u00A0000',
  },
  {
    title: 'Powerpack',
    count: 5,
    price: '227\u00A0500',
    oldPrice: '325\u00A0000',
  },
]

export const WarmConstruction: React.FC<{ type?: ComponentType }> = ({
  type = 'banner',
}) => {
  const images = useStaticQuery(graphql`
    query {
      bg: file(relativePath: { eq: "warm_construction.jpg" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
      device: file(relativePath: { eq: "ipad_with_photo360.png" }) {
        childImageSharp {
          gatsbyImageData(layout: FULL_WIDTH, placeholder: BLURRED)
        }
      }
    }
  `)

  const { locale, getPath, t } = useTranslations()

  const [modal, setModal] = useState<ModalType>()

  const renderModal = () => {
    if (!modal || type !== 'page') return null
    return (
      <ContactForm
        onClose={() => setModal(undefined)}
        title={
          modal === 'demo'
            ? t('Application demo request')
            : `${t('Buy')} Signal`
        }
        requestType={modal === 'demo' ? 'Demo' : 'Buy'}
      />
    )
  }

  return (
    <>
      <div className={s.wrap}>
        <div className={s.bg}>
          <GatsbyImage
            image={images.bg.childImageSharp.gatsbyImageData}
            className={s.bg__img}
            alt="Snow"
          />
        </div>
        <div className={s.box}>
          <div className="container">
            <h4 className={`title is-uppercase ${s.heading}`}>
              {title[locale] || title.ru}
            </h4>
            <div className={cn(s.content, type === 'page' && s.big)}>
              <div className={`relative w-100 flex ${s.leadLine}`}>
                <h6 className={`title mb-0 has-text-primary ${s.lead}`}>
                  {lead[type]?.[locale] || lead[type]?.ru}
                </h6>
                <div className={s.device}>
                  <GatsbyImage
                    image={images.device.childImageSharp.gatsbyImageData}
                    className="w-100"
                    alt="Device"
                  />
                </div>
              </div>
              <div className="columns">
                <div className="column">
                  <article className={`content has-text-white ${s.text}`}>
                    {getText(type, locale, getPath, setModal)}
                  </article>
                </div>
                <div className="column">
                  <div className="relative w-100 flex flex-wrap justify-end">
                    {items.map(({ title, count, price, oldPrice }) =>
                      renderPriceItem(title, count, price, oldPrice, locale)
                    )}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      {renderModal()}
    </>
  )
}

function renderPriceItem(
  title: string,
  count: number,
  price: string,
  oldPrice: string,
  lang: string
) {
  return (
    <div className={s.product}>
      <span className={s.product__tag}>
        {lang === 'en' ? 'Example' : 'Пример'}
      </span>
      <div className="mt-1 mb-5">
        <h6 className="is-size-4 has-text-weight-bold">Signal {title}</h6>
        <span className="is-size-4 has-text-weight-light">
          ({count} {lang === 'en' ? 'pcs' : 'шт'})
        </span>
      </div>
      <div className="has-text-primary has-text-weight-bold is-size-2 leading-none">
        {price}&nbsp;₽
      </div>
      <div className={s.product__oldPrice}>{oldPrice}&nbsp;₽</div>
    </div>
  )
}
